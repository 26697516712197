import React, { Component } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import IRLayout from "../components/Layout";
import { BookingForm } from "../components/Forms/BookingForm/BookingForm";
import ProfileInfoSmallBooking from "../components/single-profile/profile-info-small-booking";
class BookConsultant extends Component {
  constructor(props) {
    super(props);
    this.consultant = props.data.datoCmsConsultant;
  }

  render() {
    return (
      <IRLayout>
        <Helmet>
          <title>Book {this.consultant.consultantName}</title>
          <meta name="description" content={this.consultant.bioSnippet} />
          <link rel="canonical" href="https://hourly.consulting" />
        </Helmet>
        <div className="section white">
          <Container>
            <Row>
              <Col xs={12} md={2}>
                <ProfileInfoSmallBooking
                  consultant={this.consultant}
                  profilePage="booking"
                />
              </Col>
              <Col xs={12} md={10}>
                <BookingForm
                  stripePublicKey={process.env.Gatsby_stripePublicKey}
                  consultant={this.consultant}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </IRLayout>
    );
  }
}
export default BookConsultant;

export const query = graphql`
  query BookConsultantQuery($slug: String!) {
    datoCmsConsultant(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      consultantName
      firstName
      consultantImage {
        url
        fixed(width: 128) {
          ...GatsbyDatoCmsFixed
        }
      }
      bioSnippet
      bio
      expertise
      hourlyRate
      areasOfStrength {
        ... on DatoCmsStrength {
          id
          title
          strengthText
        }
      }
    }
  }
`;
