import React, { Component } from "react";
import { navigate } from "gatsby";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormValidator from "../helpers/FormValidator";
import validations from "../BookingForm/validations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/font-awesome.min.css";
import { setMinutes, setHours, addDays, subDays, format } from "date-fns";
import netlifyIdentity from "netlify-identity-widget";
var FontAwesome = require("react-fontawesome");
const axios = require("axios");
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

class _CardForm extends Component {
  constructor() {
    super();
    this.validator = new FormValidator(validations.login_rules);
    var day1 = addDays(new Date(), 1);
    day1 = day1.setHours(11, 0, 0);

    var day2 = addDays(new Date(), 2);
    day2 = day2.setHours(13, 0, 0);

    var day3 = addDays(new Date(), 3);
    day3 = day3.setHours(15, 30, 0);

    this.state = {
      name: "",
      email: "",
      phone: "",
      project_details: "",
      num_hours: "1",
      validation: this.validator.valid(),
      startDate1: day1,
      startDate2: day2,
      startDate3: day3,
      stripeErr: "test"
    };

    this.submitted = false;
  }

  handleInputChange = event => {
    console.log(event.target.id);
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ submitting: true });
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      if (this.props.stripe) {
        this.props.stripe.createToken().then(this.handleResult);
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    } else {
      this.setState({ submitting: false });
    }
  };

  handleDateChange1 = date => {
    this.setState({ startDate1: date });
  };

  handleDateChange2 = date => {
    this.setState({ startDate2: date });
  };

  handleDateChange3 = date => {
    this.setState({ startDate3: date });
  };

  handleResult = token => {
    var scheduledDate1 = format(
      new Date(this.state.startDate1),
      "Do MMMM YYYY - HH:mm"
    );
    var scheduledDate2 = format(
      new Date(this.state.startDate2),
      "Do MMMM YYYY - HH:mm"
    );
    var scheduledDate3 = format(
      new Date(this.state.startDate3),
      "Do MMMM YYYY - HH:mm"
    );

    if (token.error) {
      this.setState({ stripeErr: token.error.code });
      this.setState({ submitting: false });
      return false;
    }
    const stripeToken = token.token;
    console.log("stripe token: " + stripeToken);
    const hours = parseInt(this.state.num_hours);
    var self = this;

    axios.post("/.netlify/functions/db", {
      name: "Chris Forte",
      consultant: "Mark Iveson",
      cost: 200
    });
    let data = {
      token: stripeToken.id,
      amount: parseInt(this.props.consultant.hourlyRate) * 100 * hours,
      consultantName: this.props.consultant.consultantName,
      customerName: this.state.name,
      customerEmail: this.state.email,
      customerPhone: this.state.phone,
      projectDetails: this.state.project_details,
      numHours: this.state.num_hours,
      scheduledDate1: scheduledDate1,
      scheduledDate2: scheduledDate2,
      scheduledDate3: scheduledDate3
    };
    self.data = data;
    axios
      .post("/.netlify/functions/save-stripe-token", data)
      .then(function(response) {
        if (typeof response.data.statusCode !== "undefined") {
          if (response.data.statusCode !== "200") {
            self.setState({
              errorMessage: response.data.message,
              submitting: false
            });
            alert(response.data.message);
            console.log(response);
            return false;
          }
        } else {
          console.log(response);
          var html =
            "You have a new consultant enquiry:<br><br>" +
            "From: " +
            self.state.name +
            "<br>" +
            "Email: " +
            self.state.email +
            "<br>" +
            "Phone: " +
            self.state.phone +
            "<br><hr>" +
            "Project Details: " +
            self.state.project_details +
            "<br><hr>" +
            "Number of Hours: " +
            self.state.num_hours +
            "<br><hr>" +
            "Suggested Dates 1: " +
            scheduledDate1 +
            "<br>" +
            "Suggested Dates 2: " +
            scheduledDate2 +
            "<br>" +
            "Suggested Dates 3: " +
            scheduledDate3;
          axios
            .post("/.netlify/functions/send-email", {
              to: "chris@wpblade.com",
              from: "chris@indieridge.com",
              subject: "Consultant Enquiry",
              text: "mytext",
              html: html
            })
            .then(function(response) {
              navigate(
                "/thank-you/",
                {
                  state: {
                    values: self.data
                  }
                },
                { replace: true }
              );
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    return (
      <div className="CardDemo_xx">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <div className="section white" style={{ padding: "0px" }}>
            <h3>Call Info</h3>
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First / Last Name"
                    onChange={this.handleInputChange}
                    className={
                      validation.name.message
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  <span className="error" role="alert">
                    {validation.name.message}
                  </span>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="you@example.com"
                    onChange={this.handleInputChange}
                    className={
                      validation.name.message
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  <span className="error" role="alert">
                    {validation.email.message}
                  </span>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Group controlId="project_details">
              <Form.Label>Tell us about your project*</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                className={
                  validation.name.message ? "text-input error" : "text-input"
                }
              />
              <span className="error" role="alert">
                {validation.project_details.message}
              </span>
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </div>
          <div style={{ borderTop: "5px solid #f8f8f8", paddingTop: "16px" }}>
            <h3>How many hours do you need?</h3>
            <Form.Group controlId="num_hours">
              <Form.Label>Amount of hours you want to book:</Form.Label>
              <Form.Control as="select" onChange={this.handleInputChange}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div style={{ borderTop: "5px solid #f8f8f8", padding: "16px 0px" }}>
            <h3>Suggest a good time for the call</h3>
            <Form.Row>
              <Form.Group controlId="dt1" as={Col} md={6}>
                <Form.Label>Click to suggest 1st date</Form.Label>
                <DatePicker
                  selected={this.state.startDate1}
                  placeholderText="Click to select a date"
                  onChange={this.handleDateChange1}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  minTime={setHours(setMinutes(new Date(), 0), 6)}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  minDate={subDays(new Date(), 0)}
                  defaultValue={null}
                />
              </Form.Group>
              <Form.Group controlId="dt2" as={Col} md={6}>
                <Form.Label>Click to suggest 2nd date</Form.Label>
                <DatePicker
                  selected={this.state.startDate2}
                  onChange={this.handleDateChange2}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  placeholderText="Click to select a date"
                />
              </Form.Group>
              <Form.Group controlId="dt3" as={Col} md={6}>
                <Form.Label>Click to suggest 3rd date</Form.Label>
                <DatePicker
                  selected={this.state.startDate3}
                  onChange={this.handleDateChange3}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  placeholderText="Click to select a date"
                />
              </Form.Group>
            </Form.Row>
          </div>
          <Form.Row>
            <Form.Group controlId="sripeElement" as={Col} sm={12}>
              <Form.Label>Card Details</Form.Label>
              <CardElement onChange={this.handleChange} {...createOptions()} />
              <div className="error stripeErrorContainer" role="alert">
                {this.state.errorMessage}
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="sripeElement" as={Col} sm={12}>
              <button className="paymentButton">
                {this.state.submitting ? (
                  <FontAwesome
                    className="super-crazy-colors"
                    name="spinner"
                    spin
                    style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
                  />
                ) : (
                  "Pay"
                )}
              </button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class BookingForm extends Component {
  constructor(props) {
    super();
    this.state = { stripe: null };
  }

  componentDidMount() {
    // Create Stripe instance in componentDidMount
    // (componentDidMount only fires in browser/DOM environment)
    this.setState({ stripe: window.Stripe(this.props.stripePublicKey) });

    //const user = netlifyIdentity.currentUser();
    //console.log(user);
    //netlifyIdentity.on('init', user => console.log('init', user));
    /*
	netlifyIdentity.on('init', user => {
			console.log(user);
			if ( !user ) {
				console.log('aaaa');
				netlifyIdentity.open('login'); // open the modal to the login tab
			}
		}
	);
	netlifyIdentity.on('login', user => console.log('login', user));
	netlifyIdentity.on('logout', () => console.log('Logged out'));
	netlifyIdentity.on('error', err => console.error('Error', err));
	netlifyIdentity.on('open', () => console.log('Widget opened'));
	netlifyIdentity.on('close', () => console.log('Widget closed'));
    netlifyIdentity.init();
 	*/
  }

  render() {
    const user2 = netlifyIdentity.currentUser();
    console.log("user2: ", user2);
    if (!user2) {
      console.log("not logged in");
      //netlifyIdentity.open("login"); // open the modal to the login tab
    }
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardForm
            consultant={this.props.consultant}
            handleResult={this.handleResult}
            handleFormChange={this.props.handleFormChange}
          />
        </Elements>
      </StripeProvider>
    );
  }
}
